<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <h4 class="mb-3">
        Добавление контейнера
      </h4>
      <p>Вам необходимо заполнить доступные поля, чтобы система смогла произвести проверку на наличие контейнера.</p>
      <div class="form-sections">
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по Контейнеру
          </h5>
          <b-row>
            <b-col md="6">
              <b-form-group
                id="numberInputGroup5"
                label="Номер"
                label-for="registration-number"
              >
                <b-form-input
                  id="registration-number"
                  v-model.trim="$v.form.number.$model"
                  type="text"
                  name="registration-number"
                  :state="chkState('number')"
                  aria-describedby="input1LiveFeedback5"
                  autocomplete="registration-number"
                  @focus="rememberPreviousValue($v.form.number.$model, 'number')"
                  @blur="checkContainer($v.form.number.$model, 'number')"
                />
                <b-form-invalid-feedback
                  id="input1LiveFeedback5"
                >
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="nameInputGroup4"
                class="form-group-el-select"
                label="Название"
                label-for="container-make"
              >
                <b-form-input
                  id="container-name"
                  v-model="calculatedName"
                  type="text"
                  name="container-name"
                  :disabled="true"
                  aria-describedby="input1LiveFeedback5"
                  autocomplete="container-name"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="containerMakeInputGroup4"
                class="form-group-el-select"
                label="Тип контейнера"
                label-for="container-make"
              >
                <el-select
                  v-model="form.containerType.id"
                  class="form-control"
                  :disabled="!checkContainerDone || existingContainer"
                  name="container-make"
                  placeholder="Выберите тип"
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :loading="loading"
                  @change="getContainerTypeData(form.containerType.id)"
                >
                  <el-option
                    v-for="item in containerTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="volumeInputGroup6"
                label="Объем"
                label-for="body-volume"
              >
                <b-form-input
                  id="body-volume"
                  v-model.trim="$v.form.containerType.volume.$model"
                  type="number"
                  name="body-volume"
                  :disabled="true"
                  autocomplete="body-volume"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="heightInputGroup7"
                label="Высота"
                label-for="body-height"
              >
                <b-form-input
                  id="body-height"
                  v-model.trim="$v.form.containerType.height.$model"
                  type="number"
                  name="body-height"
                  :disabled="true"
                  autocomplete="body-height"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="widthInputGroup8"
                label="Ширина"
                label-for="body-width"
              >
                <b-form-input
                  id="body-width"
                  v-model.trim="$v.form.containerType.width.$model"
                  type="number"
                  name="body-width"
                  :disabled="true"
                  autocomplete="body-width"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="depthInputGroup9"
                label="Глубина"
                label-for="body-depth"
              >
                <b-form-input
                  id="body-depth"
                  v-model.trim="$v.form.containerType.depth.$model"
                  type="number"
                  name="body-depth"
                  :disabled="true"
                  autocomplete="body-depth"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                id="maximumPayloadInputGroup9"
                label="Грузоподъемность"
                label-for="body-maximumPayload"
              >
                <b-form-input
                  id="body-maximumPayload"
                  v-model.trim="$v.form.containerType.maximumPayload.$model"
                  type="number"
                  name="body-maximumPayload"
                  :disabled="true"
                  autocomplete="body-maximumPayload"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :class="{'loading' : loading}"
          :disabled="$v.form.$invalid || loading"
        >
          Создать
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {validation} from '../../components/mixins/validation';
import {required, minLength, email} from 'vuelidate/lib/validators';
import notifications from '../../components/mixins/notifications';
import {containersTypesList, contractorsContainerCreate, checkContractorsContainerWithNumber, containerTypeRead} from '../../services/api';

export default {
  name: 'ContainerAdd',
  mixins: [validationMixin, validation, notifications],
  data() {
    return {
      containerTypes: [],
      checkContainerDone: false,
      existingContainer: false,
      form: {
        number: '',
        name: '',
        containerType: {
          id: null,
          volume: '',
          height: '',
          width: '',
          depth: '',
          maximumPayload: '',
        },
      },
      fieldsTriggeringCheckContainerOldValues: {
        number: '',
      },
      feedBack: 'secondary',
      loading: false,
    };
  },
  computed: {
    calculatedName() {
      if (this.form.number && this.form.name) {
        return this.form.number + ' ' + this.form.name;
      } else {
        return '';
      }
    },
    formStr() {
      const calculationFormData = {};
      calculationFormData.name = this.calculatedName;
      calculationFormData.number = this.form.number;
      calculationFormData.containerTypeId = this.form.containerType.id;
      calculationFormData.width = this.form.containerType.width;
      calculationFormData.height = this.form.containerType.height;
      calculationFormData.depth = this.form.containerType.depth;
      calculationFormData.maximumPayload = this.form.containerType.maximumPayload;
      calculationFormData.volume = this.form.containerType.volume;
      return calculationFormData;
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations: {
    form: {
      number: '',
      name: '',
      containerType: {
        volume: '',
        height: '',
        width: '',
        depth: '',
        maximumPayload: '',
      },
    },
  },
  mounted() {
    this.containersTypesList({detalizationLevel: 'minimum'});
  },
  methods: {
    clearForm() {
      this.form.number = '';
      this.form.name = '';
      this.form.containerType.volume = '';
      this.form.containerType.height = '';
      this.form.containerType.width = '';
      this.form.containerType.depth = '';
      this.form.containerType.maximumPayload = '';
      this.form.containerType.id = null;
    },
    clearFormBeforeCheckContainer() {
      this.form.name = '';
      this.form.containerType.volume = '';
      this.form.containerType.height = '';
      this.form.containerType.width = '';
      this.form.containerType.depth = '';
      this.form.containerType.maximumPayload = '';
      this.form.containerType.id = null;
    },
    rememberPreviousValue(value, input) {
      this.fieldsTriggeringCheckContainerOldValues[input] = value;
    },
    async containersTypesList(params) {
      this.loading = true;
      const response = await containersTypesList(params);
      if (response && response.status === 200) {
        this.containerTypes = response.data.items.map((type) => ({
          value: type.id,
          text: type.name,
        }));
      }
      this.loading = false;
    },
    async getContainerTypeData(containerTypeId) {
      this.loading = true;
      const response = await containerTypeRead(containerTypeId);
      if (response && response.status === 200) {
        this.form.name = response.data.name;
        this.form.containerType.volume = response.data.volume;
        this.form.containerType.height = response.data.height;
        this.form.containerType.width = response.data.width;
        this.form.containerType.depth = response.data.depth;
        this.form.containerType.maximumPayload = response.data.maximumPayload;
        this.form.containerType.id = response.data.id;
      }
      this.loading = false;
    },
    async checkContainer(value, input) {
      if (this.fieldsTriggeringCheckContainerOldValues[input] !== value) {
        this.clearFormBeforeCheckContainer();
        if (this.form.number) {
          this.loading = true;
          this.checkContainerDone = false;
          const response = await checkContractorsContainerWithNumber(this.$store.state.user.ownerId, this.form.number);
          if (response && response.status === 200) {
            if (response.data) {
              this.checkContainerDone = true;
              this.existingContainer = true;
              this.showInfo('Данный контейнер уже существует');
              this.$nextTick(() => {
                this.getContainerTypeData(response.data.containerType.id);
              });
            } else {
              this.checkContainerDone = true;
              this.existingContainer = false;
              this.showInfo('Данный контейнер не найден');
            }
          }
          this.loading = false;
        } else if (this.checkContainerDone) {
          this.checkContainerDone = false;
        }
      }
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await contractorsContainerCreate(this.$store.state.user.ownerId, this.formStr);
        if (response && response.status === 201) {
          this.showSuccess('Контейнер создан');
          this.$router.push({path: '/containers'});
        }
        this.loading = false;
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
  },
};
</script>

<style lang="scss">
</style>
