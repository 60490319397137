
export const validation = {
  methods: {
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
      // this.$refs.recaptcha.execute()
    },
  },
  computed: {
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
};

export const isInn =(inn) => {
  let result = false;
  if (typeof inn === 'number') {
    inn = inn.toString();
  } else if (typeof inn !== 'string') {
    inn = '';
  }
  if (!inn.length) {
    result = true;
  } else if (/[^0-9]/.test(inn)) {
    result = true;
  } else {
    switch (inn.length) {
    case 10:
      result = true;
      break;
    case 12:
      result = true;
      break;
    }
  }
  return result;
};
export const isInnFormat = (inn) => {
  let result = false;
  const checkDigit = function(inn, coefficients) {
    let n = 0;
    for (const i in coefficients) {
      n += coefficients[i] * inn[i];
    }
    return parseInt(n % 11 % 10);
  };
  switch (inn.length) {
  case 10:
    const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
    if (n10 === parseInt(inn[9])) {
      result = true;
    }
    break;
  case 12:
    const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
    const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
    if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
      result = true;
    }
    break;
  }
  return result;
};


export const isKpp = (kpp) => {
  let result = false;
  if (typeof kpp === 'number') {
    kpp = kpp.toString();
  } else if (typeof kpp !== 'string') {
    kpp = '';
  }
  if (!kpp.length) {
    result = false;
  } else if (kpp.length !== 9) {
    result = false;
  } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
    result = false;
  } else {
    result = true;
  }
  return result;
};


export const isRs = (rs) => {
  let result = false;
  if (typeof rs === 'number') {
    rs = rs.toString();
  } else if (typeof rs !== 'string') {
    rs = '';
  }
  if (!rs.length) {
    result = false;
  } else if (/[^0-9]/.test(rs)) {
    result = false;
  } else if (rs.length !== 20) {
    result = false;
  } else {
    result = true;
  }
  return result;
};

export const isOgrn = (ogrn) => {
  let result = false;
  if (typeof ogrn === 'number') {
    ogrn = ogrn.toString();
  } else if (typeof ogrn !== 'string') {
    ogrn = '';
  }
  if (!ogrn.length) {
    result = false;
  } else if (/[^0-9]/.test(ogrn)) {
    result = false;
  } else if (ogrn.length !== 13) {
    result = false;
  } else {
    const n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1));
    if (n13 === parseInt(ogrn[12])) {
      result = true;
    } else {
      result = false;
    }
  }
  return result;
};

export const isOgrnip = (ogrnip) => {
  let result = false;
  if (typeof ogrnip === 'number') {
    ogrnip = ogrnip.toString();
  } else if (typeof ogrnip !== 'string') {
    ogrnip = '';
  }
  if (!ogrnip.length) {
    result = false;
  } else if (/[^0-9]/.test(ogrnip)) {
    result = false;
  } else {
    const n15 = parseInt((parseInt(ogrnip.slice(0, -1)) % 13).toString().slice(-1));
    if (n15 === parseInt(ogrnip[14])) {
      result = true;
    } else {
      result = false;
    }
  }
  return result;
};

export const isBik = (bik) => {
  let result = false;
  if (typeof bik === 'number') {
    bik = bik.toString();
  } else if (typeof bik !== 'string') {
    bik = '';
  }
  if (!bik.length) {
    result = false;
  } else if (/[^0-9]/.test(bik)) {
    result = false;
  } else if (bik.length !== 9) {
    result = false;
  } else {
    result = true;
  }
  return result;
};

export const isKs = (ks) => {
  let result = false;
  if (typeof ks === 'number') {
    ks = ks.toString();
  } else if (typeof ks !== 'string') {
    ks = '';
  }
  if (!ks.length) {
    result = false;
  } else if (/[^0-9]/.test(ks)) {
    result = false;
  } else if (ks.length !== 20) {
    result = false;
  } else {
    result = true;
  }
  return result;
};

export const isTsogr = (ogr) => {
  let result = false;
  if (!ogr.length) {
    result = false;
  } else if (/^[а-яА-Яё\-\s]{1}(?!0{3})[0-9]{3}[а-яА-Яё\-\s]{2}[0-9]{2,3}$/.test(ogr)) {
    result = true;
  } else if (/^[а-яА-Яё\-\s]{2}(?!0{4})[0-9]{4}[0-9]{2,3}$/.test(ogr)) {
    result = true;
  } else {
    result = false;
  }
  return result;
};
export const noWhiteSpace =(s) => {
  return !(/\s/g.test(s));
};
